@use 'variables' as *;
.card {
  background: white;
  border-radius: 0;
  display: block;

  &.gray,
  &.blue {
    border-radius: 8px;

    .card-header {
      padding: 24px 32px;
      display: block;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .card-body {
      display: block;
      padding: 16px 32px;
    }
  }
  &.gray {
    border: 1px solid color('grey light');

    .card-header {
      background: color('bg');
    }
  }
  &.blue {
    border: 1px solid color('grey light');
    // box-shadow: 0 2px 10px 0 #0000000D;
    .card-body {
      padding: rem(24);
    }
    .card-header {
      padding: rem(20) rem(24);
      border-bottom: 1px solid color('smoke');
    }
    .card-footer {
      display: block;
      padding: rem(20) rem(24);
      border-top: 1px solid color('smoke');
    }
  }
  &.card-shadow {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
}
