@use 'variables' as *;

.typo {
  &-header {
    &-xxl {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &-body {
    &-11 {
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
    }
    &-m {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    &-xs {
      font-size: 10px;
      line-height: 12px;
    }

    &-s {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
    }
  }
}

.header {
  &-24{
    font-size: rem(20);
    font-weight: 600;
    line-height: rem(30);
  }
  &-20{
    font-size: rem(20);
    line-height: rem(24);
    font-weight: 600;
  }
  &-18{
    font-size: rem(18);
    line-height: rem(20);
    font-weight: 600;
  }
  &-16{
    font-size: rem(16);
    font-weight: 600;
    line-height: rem(20);
  }
  &-14{
    font-size: rem(14);
    font-weight: 700;
    line-height: rem(16);
  }
  &-12{
    font-size: rem(12);
    line-height: rem(16);
    font-weight: 700;
  }
}
