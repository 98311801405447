@import 'variables';


.tooltip-inner {
  padding: 4px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  border-radius: 8px;
  letter-spacing: 0.2px;
  text-align: left;
  box-shadow: 4px 4px 10px rgba(0,0,0, 0.15);
}
