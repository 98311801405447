@use 'sass:map';

$SizesCustom: (
  s: 8px,
  xs: 12px,
  m: 16px,
  xm: 20px,
  l: 24px,
  xl: 34px,
) !default;
$SpacersCustom: (
  0: 0,
  xs: 4px,
  s: 8px,
  m: 16px,
  xm: 20px,
  l: 24px,
  xl: 30px,
  xxl: 40px,
  auto: auto
) !default;

@mixin set-text-sizes($newSizes) {
  $SizesCustom: map.merge($SizesCustom, $newSizes) !global;

  @each $size, $length in $SizesCustom {
    .text-#{$size} {
      font-size: $length !important;
    }
  }
}

@mixin set-spacers($newSpacers) {
  $SpacersCustom: map.merge($SpacersCustom, $newSpacers) !global;

  @each $size, $length in $SpacersCustom {
    .p-#{$size} {
      padding: $length !important;
    }
    .pl-#{$size} {
      padding-left: $length !important;
    }
    .pr-#{$size} {
      padding-right: $length !important;
    }
    .pt-#{$size} {
      padding-top: $length !important;
    }
    .pb-#{$size} {
      padding-bottom: $length !important;
    }
    .px-#{$size} {
      padding-left: $length !important;
      padding-right: $length !important;
    }
    .py-#{$size} {
      padding-bottom: $length !important;
      padding-top: $length !important;
    }
    .m-#{$size} {
      margin: $length !important;
    }
    .ml-#{$size} {
      margin-left: $length !important;
    }
    .mr-#{$size} {
      margin-right: $length !important;
    }
    .mt-#{$size} {
      margin-top: $length !important;
    }
    .mb-#{$size} {
      margin-bottom: $length !important;
    }
    .mx-#{$size} {
      margin-left: $length !important;
      margin-right: $length !important;
    }
    .my-#{$size} {
      margin-bottom: $length !important;
      margin-top: $length !important;
    }
  }
}
