@use 'variables' as *;
$border-radius: 8px;

.modal {
  &-open {
    overflow: hidden;
  }
  &-header {
    position: relative;
    padding: 30px 40px 15px;
    border: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
    }

    .close-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      color: color('grey');
      font-size: 0;
      .icon {
        font-size: 28px;
      }
    }
  }

  &-body {
    padding: 15px 40px;

    .boat-alerts-history-modal & {
      max-height: calc(100vh - 200px);
      overflow: auto;
    }
  }

  &-footer {
    padding: 16px 24px;
    border: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top: 1px solid color('smoke');
  }

  &-dialog {
    max-width: 980px;

    &.booking-boat-confirmation-modal-container {
      max-width: 830px;
    }

    &.boat-alerts-history-modal {
      max-width: 1054px;
    }

    &.modal-confirm,
    &.boat-movement-modal,
    &.modal-lg {
      max-width: 750px;
    }

    &.modal-md {
      max-width: 624px;
    }
    &.modal-sm {
      max-width: 480px;
    }
    &.modal-xs {
      max-width: 400px;
    }
  }

  &-content {
    border-radius: $border-radius;
    .success-text {
      color: #25B10D;
    }
  }
}
.modal-dialog.hide-modal{
  opacity: 0 !important;
  transform: translateY(-40px) !important;

}
.mms-wallet-steps-modal{
  max-width: 480px;
}
.transfer-modal{
  .modal-body {
    padding: 16px 24px;
  }
  .modal-header {
    padding: 24px 24px 0px;
    .title {
      text-transform: none;
    }
  }
}
