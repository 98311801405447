@use 'variables' as *;

.nav {
  &-tabs {
    background-color: color(bg);
    padding: rem(16) 0;
    margin: 0;
    border-bottom: none;

    .nav {
      &-link {
        background: #fff;
        min-width: 108px;
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
        padding: 0 10px;
        user-select: none;
        height: 32px;
        margin-right: 16px;
        color: $color-gray;
        border: none;
        box-shadow: 0 2px 10px rgb(0 0 0 / 2.77%);
        border-radius: 4px;

        &:hover {
          color: color(secondary);
        }

        &.active,
        .nav-item.show & {
          background-color: color(secondary);
          color: white;
          // color: #495057;
          // background-color: #fff;
          // border-color: #dee2e6 #dee2e6 #fff;
        }

        &.disabled {
          pointer-events: all;
          cursor: not-allowed;
        }
      }
    }

    &--no-bg {
      background-color: transparent;

      .nav {
        &-link {
          background-color: color('bg');
        }
      }
    }
  }
}
