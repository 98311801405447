@use 'variables' as *;

$border-style: 1px solid color('grey light');
$border-radius: 4px;

.table {
  width: 100%;

  .link {
    color: color('primary');
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    user-select: none;

    &--size {
      &-medium {
        font-size: 14px;
      }
    }

    &.link--color-secondary {
      color: color('secondary');
    }

    &--no-transform {
      text-transform: none;
    }

    &:hover {
      color: darken($color-primary, 20%);
    }
  }

  th,
  td {
    padding: rem(20) rem(4) rem(20) rem(20);

    &.name a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      max-width: 400px;
  }

    &.text-right {
      padding-right: rem(20);
      padding-left: rem(4);
    }

    &:last-child {
      padding-right: rem(20);
    }
  }

  th {
    font-weight: 700;
    color: color('secondary');
    text-align: left;
    font-size: 12px;
    background: color('white');
    vertical-align: top;

    &.text-right {
      text-align: right;
    }
  }

  // Support non tr tags
  tbody > * {
    &:first-child {
      td:first-child {
        border-top-left-radius: $border-radius;
      }
      td:last-child {
        border-top-right-radius: $border-radius;
      }
    }
    &:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
    &:last-child {
      td {
        border-bottom: $border-style;
      }
    }
  }

  td {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: color('text');
    border-top: $border-style;

    &:first-child {
      border-left: $border-style;
    }
    &:last-child {
      border-right: $border-style;
    }

    &.actions {
      &--hide-overflow {
        button {
          position: relative;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.overflow-spinner__wrapper {
            display: inline-block;
          }
        }
      }
    }
  }

  .max-width {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .actions {
    text-align: right;
    width: 84px;
  }

  &--no-border {
    th {
      padding-top: 0;
      padding-bottom: 0;
    }
    td {
      border: none !important;
    }
  }

  &--no-aside-space {
    * > td,
    * > th {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &--separate {
    border-collapse: separate;
    border-spacing: 0 8px;

    tr {
      td {
        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }

    th {
      padding-bottom: 8px;
    }

    td {
      border-bottom: $border-style;
    }
  }

  &--selection {
    tr {
      cursor: pointer;
      &:hover,
      &.active {
        td {
          border-color: color('grey');
        }
      }
    }
  }

  &--with-background {
    td {
      background-color: color('bg');
    }
  }

  &--compact {
    th,
    td {
      padding: 0 0;
      background: transparent;
      border: 0 !important;
      &:last-child {
        padding-right: 0;
      }
    }
    td {
      padding: 20px 0;
      border: 0 !important;
      border-top: 1px solid color('grey light') !important;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 8px;
            border-top: 0 !important;
          }
        }
        &:last-child {
          td {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  &--fixed {
    table-layout: fixed;
  }
}
.berth-selection-table {
  width: 100%;
  border-collapse: unset;
  .title {
    margin: 48px 0 24px;
    font-size: 14px;
    line-height: 17px;
  }
  .subtitle {
    margin: 24px 0 12px;
    color: color('grey');
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
  tbody {
    box-shadow: none;
  }

  tr:not(.no-items) {
    cursor: pointer;
    &:hover,
    &.active {
      td {
        background-color: #ECF5FE;
      }
    }
  }

  th {
    padding: 12px 12px 8px;

    &:first-child {
      padding-left: 24px;
    }
  }

  td {
    padding: 12px 12px;

    font-size: 12px;
    line-height: 15px;

    &:first-child {
      padding-left: 24px;

    }

  }
  td,
  th {
    &:last-child {
      text-align: right;
    }
  }
}
