@forward './../../global/variables';

@use './../../global/variables' as *;

$color-primary: #126cea;
$color-secondary: #b96f54;
$color-black: #1b253a;
$color-kashmir: #506a8d;
$color-smoke: #ECF5FE;
$color-gray: #9ba9b8;
$color-gray-light: #D9E2EB;
$color-gray-lighter: #fafbfc;
$color-gray-light-cp: #6886a7;
$color-red: #ff6161;
$color-red-2: #d90000;
$color-magenta: #9767DB;
$color-orange-dark: #C46E0C;
$color-red-dark: #BD0909;
$gradient-primary: linear-gradient(93.74deg, #1b8bf2 14.08%, #0d5ae5 91.13%);
$input-background-disabled-color: #f9f9f9;
$warning: #F58A0F;
$map-text-green: #25b10e;
$color-alabaster: #f8fafd;

@include define-colors(
  (
    'primary': $color-primary,
    'primary lighter': #e7f0fd,
    'smoke':$color-smoke,
    'primary light': #bcd6f9,
    'secondary': $color-kashmir,
    'danger': $color-red,
    'danger dark': $color-red-dark,
    'danger2': $color-red-2,
    'orange dark':$color-orange-dark,
    'grey': $color-gray,
    'grey light': $color-gray-light,
    'grey light cp': $color-gray-light-cp,
    'grey lighter': $color-gray-lighter,
    'text': $color-black,
    'black': $color-black,
    'white': white,
    'error': $color-red,
    'magenta':$color-magenta,
    'bg': $color-gray-lighter,
    'bgDark': $color-black,
    'formBorder': $color-gray-light,
    'formLabel': $color-kashmir,
    'navTabsColor': $color-gray,
    'gradient primary': $gradient-primary,
    'input background disabled': $input-background-disabled-color,
    'success': $success,
    'warning': $warning,
    'calendarBorder': $color-gray-light,
    'tooltip background': #f5f7fa,
    'tooltip text': $color-kashmir,
    'map text green': $map-text-green,
    'alabaster': $color-alabaster,
    'badge success': $map-text-green,
    'selected background': #ebf5ff
  )
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($theme-colors-rgb, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($theme-colors-rgb, rgba-css-var, '$key', 'bg');
$primary: $color-primary !default;
$danger: $color-red;
$black: $color-black;
$success: #2ac565;
$color-occupied-own-berth: #cbefa2;
$border-color-occupied-own-berth: #6da82b;
$color-occupied-foreign-berth: #a9dfff;
$border-color-occupied-foreign-berth: #478bb2;
$color-on-cruise: #ffeab5;
$border-color-on-cruise: #e5b53d;
$cubic-transition: cubic-bezier(0.25, 0.8, 0.25, 1);
$base-font-color: $color-black;
$base-font-size: 16px;
$base-font-family: 'Montserrat', sans-serif;
$btn-border-radius: 0 !default;
$navbar-height: rem(72);
$navbar-shadow: 0 2px 10px 0 rgb(0 0 0 / 3%);
