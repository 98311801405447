@import 'variables';

// TODO: move to link.scss with better name
.details-link {
  display: inline-block;
  color: $primary;
  font-size: 14px;
  font-weight: 600;
}
.back-link{
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
    i{
      margin-right: 2px;
      margin-top: -3px;
      font-size: 20px;
    }
}
.reset-button{
  font-size: 12px;
  font-weight: 700;
  text-align: right;
  color: $primary;
  text-transform: uppercase;
  &:hover{
    color: darken($primary, 10%);
  }
}
.action-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: white;
  font-size: 18px;
  border: 1.5px solid color('primary');
  color: color('primary');
  border-radius: 5px;

  &:focus {
    border-color: color('primary');
  }

  &:after {
    content: '•••' !important;
    border: none;
    font-size: 14px;
    vertical-align: initial;
    margin-left: 0;
  }
  &.color-gray {
    border: 1.5px solid $color-gray;
    color: $color-gray;
  }
  &-indicator {
    position: absolute;
    top: -3px;
    right: -3px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
}

.action-danger {
  color: color('error');
}

.btn-pill-primary-light {
  background: color('primary lighter');
  border: 1px solid color('primary light');
  border-radius: 37px;
  padding: 2px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  //color: #1B253A;
  transition: border-color $time-transition;

  @include hover {
    border-color: color('primary');
  }
}
