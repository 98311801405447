@use "variables" as *;
.description-list{

    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 12px;
  font-weight: 600;
    dt, dd{
      margin-bottom: 12px;
    }
    dt{
      width: 40% ;
      padding-right: 10px;
      white-space: pre-wrap;
      word-break: break-word;
      color:  rgba(80, 106, 141, 1);
    }
    dd{
      width: 60%;

      word-break: break-word;
    }

}
