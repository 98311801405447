@use "variables" as *;

/**
TODO: We should use different class to add chevron. Directive [ngbDropdownToggle] on default add class "dropdown-toggle"
 and it's a problem to get rid of it. Workaround is add class "no-chevron"
 */
.dropdown-toggle:not(.action-button) {
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:after {
    position: absolute;
    right: -6px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-weight: 500;
    transition: transform 0.35s ease-in-out;
    color: $color-gray;
    font-family: font('icomoon');
    content: $i-dropdown;
    height: 24px;
    width: 24px;
    text-align: right;
    font-size: 26px;
    border: none;
    vertical-align: middle;
    line-height: 24px;

    .dropdown.show & {
      transform: rotateX(180deg);
    }
  }

  &.active {
    &:after {
      color: color('primary');
    }
  }

  &.no-chevron {
    &:after {
      content: none !important;
    }
  }
}
