@use 'variables' as *;

@use '../global/global';
@use '../global/mixins/other';
@use '../global/settings/spaces' as *;

@use 'libs/ui/src/lib/styles/mms-panel/components/__components';

@use './utils/_utils';

@include other.headings {
  font-weight: bold;
}

@include set-text-sizes(());
@include set-spacers(());

body {
  position: relative;
  margin: 0;
  background-color: color(bg);
  color: #1b253a;
  font-size: $font-size-root;
  overflow-x: hidden;
  transition: background 0.35s ease-in-out;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
