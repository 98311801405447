@use 'variables' as *;
@import 'buttons';
@import 'table';
@import 'nav';
@import 'modal';
@import 'datepicker';
@import "description-list";
@import 'dropdown';
@import 'map';
@import 'tooltip';
@import "card";
.notify {
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  border-radius: 20px;
  background: color('danger');
}



.move-cursor {
  cursor: grabbing !important;
}

/**
@deprecated use dm-ui-horizontal-line
*/
.visual-line {
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    margin: rem(16) 0;
    background: color('grey light');
  }
}
